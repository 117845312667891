import React from 'react';

import styled from 'styled-components';

/* eslint-disable-next-line */
export interface NotFoundProps {}

const StyledNotFound = styled.div``;

export function NotFound(props: NotFoundProps) {
  return (
    <StyledNotFound>
      <h1>404 NotFound!</h1>
    </StyledNotFound>
  );
}

export default NotFound;

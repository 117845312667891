import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import SpartansLogo from '@web/assets/spartans_logo.svg';
import { environment } from '@web/environments/environment';
import { trackEvent } from '@web/lib/plausibleLib'; 

/* eslint-disable-next-line */
export interface RegisterProps {}

const StyledRegister = styled.div`
  text-align: center;

  form {
    margin-top: 5rem;
    display: inline-block;
    width: 400px;

    @media all and (max-width: 440px) {
      width: 90%;
    }
  }

  img {
    margin-top: 5rem;
    width: 500px;

    @media all and (max-width: 550px) {
      width: 90%;
    }
  }
`;

const StyledTextInput = styled.input`
  width: calc(100% - 25px); // 100% - padding-left
  padding-left: 25px;
  height: 50px;
  background-color: rgba(238, 238, 238, 0.3);
  border: none;
  border-radius: 25px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgb(255, 255, 255);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
`;

const StyledButton = styled.button`
  width: 100%;
  border: none;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  height: 50px;
  background-color: rgb(9, 169, 17);
  border-radius: 25px;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StyledError = styled.h3`
  font-family: Montserrat, sans-serif;
  color: red;
`;

export function Register(props: RegisterProps) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [error, setError] = useState('');

  const history = useHistory();

  function validateForm() {
    const emailRegexp = new RegExp(
      /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/
    );
    const passwordRegexp = new RegExp(/^(?=.*[A-Za-z]).{8,}$/);

    if (!name.trim()) {
      setError('Introduce tu nombre');
      trackEvent('signup', { props: { status: 'Error', message: 'No name' }});
      return false;
    }

    if (!email.trim() || !emailRegexp.test(email.trim())) {
      setError('Introduce una dirección de email válida');
      trackEvent('signup', { props: { status: 'Error', message: 'Invalid email' }});
      return false;
    }

    if (!password || password.length < 8) {
      setError('La contraseña debe ser de al menos 8 caracteres');
      trackEvent('signup', { props: { status: 'Error', message: 'Short password' }});
      return false;
    }

    if (!passwordRegexp.test(password)) {
      setError('La contraseña debe tener almenos una letra');
      trackEvent('signup', { props: { status: 'Error', message: 'Password without alphabetic char' }});
      return false;
    }

    if (password !== passwordRepeat) {
      setError('Las contraseñas no coinciden');
      trackEvent('signup', { props: { status: 'Error', message: 'Missmatching passwords' }});
      return false;
    }

    return true;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    trackEvent('signup', { props: { status: 'Submit' }});

    if (!validateForm()) {
      return;
    }

    const response = await fetch(`${environment.apiUrl}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name.trim(),
        email: email.trim(),
        password,
      }),
    });

    if (response.ok) {
      trackEvent('signup', { props: { status: 'OK' }});
      const jsonResponse = await response.json();
      history.push('/login');
    } else {
      const jsonResponse = await response.json();
      trackEvent('signup', { props: { status: 'Error', message: jsonResponse.message }});
      setError(jsonResponse.message);
    }
  }

  return (
    <StyledRegister>
      <img width="500px" src={SpartansLogo} alt="Spartans Logo" />
      <br />
      <form onSubmit={handleSubmit}>
        <StyledTextInput
          id="name"
          autoFocus
          type="text"
          placeholder="Nombre"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <br />
        <StyledTextInput
          id="email"
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <StyledTextInput
          id="password"
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <StyledTextInput
          id="password-repeat"
          type="password"
          placeholder="Repetir contraseña"
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
        />
        <br />
        <StyledButton type="submit">Registrarme</StyledButton>
      </form>
      <br />
      <Link to="/login">¿Ya tienes una cuenta?</Link>
      <StyledError>{error}</StyledError>
    </StyledRegister>
  );
}

export default Register;

import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { History } from 'history';
import moment from 'moment';
import styled from 'styled-components';

import Workout from '@web/app/workout/workout';
import Modal from '@web/app/modal/modal';
import Video from '@web/app/video/video';
import { useAppContext } from '@web/lib/contextLib';
import { IWorkout } from '@spartans/types';
import { environment } from '@web/environments/environment';


/* eslint-disable-next-line */
export interface WorkoutPageProps {}

const StyledWorkoutPage = styled.div`
  @font-face {
    font-family: chalk;
    src: url('assets/chawp.otf');
  }
  font-family: chalk;
  font-size: large;
  color: white;

  a {
    color: white;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  a:active {
    text-decoration: underline;
  }

  h1 {
    margin: 0;
    padding: 1rem;
    text-align: center;
  }

  background-image: url('assets/chalkboard_compressed.jpg');
  background-position: center;
  background-size: cover;
  background-color: #cccccc;
  max-width: 100rem;
  min-height: 50rem;
  padding: 0;
  margin: auto;
  margin-top: 1rem;
  max-width: 1600px;

  @media all and (max-width: 1500px) {
    margin: 0px;
  }
`;
const StyledWorkoutZone = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% - 3 * 10px) / 4); /*4 items visible*/
  grid-gap: 10px;
  margin: auto;
  overflow: auto;
  min-height: inherit;
  scroll-snap-type: y mandatory;

  @media all and (max-width: 1500px) {
    margin: 0px;
    grid-auto-columns: calc((100% - 2 * 5px) / 3); /*three items visible*/
    grid-gap: 5px;
  }

  @media all and (max-width: 1000px) {
    margin: 0px;
    grid-auto-columns: calc((100% - 5px) / 2); /*two items visible*/
    grid-gap: 5px;
  }

  @media all and (max-width: 500px) {
    margin: 0px;
    grid-auto-columns: 100%; /*one item visible*/
    grid-gap: 5px;
  }
`;

interface ParamTypes {
  week: string
}

export function WorkoutPage(props: WorkoutPageProps) {
  const { isAuthenticated } = useAppContext();
  const history: History = useHistory();
  const [video, setVideo] = useState('');

  const { week } = useParams<ParamTypes>();
  const weekInt = parseInt(week, 10);
  const currentWeek = moment().isoWeek() + 105 - 1;
  const [workoutGroups, setWorkoutGroups] = useState([]);

  if (!isAuthenticated) {
    history.push('/login');
  }

  if (!week || weekInt > currentWeek || weekInt <= 0) {
    history.push(`/week/${currentWeek}`);
  }

  useEffect(() => {
    fetch(`${environment.apiUrl}/workout/week/${week}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${isAuthenticated}`,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          history.push('/logout');
          return;
        }
        if (response.ok) {
          return response.json();
        }
        throw response.statusText;
      })
      .then((response) => {
        if (response.workouts) {
          const otherWorkoutGroups = workoutGroups.filter(
            (workoutGroup) => workoutGroup.week !== weekInt
          );
          setWorkoutGroups([
            { week: weekInt, workouts: response.workouts },
            ...otherWorkoutGroups,
          ]);
        }
      })
      .catch((error) => console.error(error));
  }, [isAuthenticated, week]);

  const videoWidth = screen.width > 640 ? 640 : screen.width;

  const showPrevious = () => {
    if (weekInt <= 1) {
      return false;
    }
    return true;
  };

  const showNext = () => {
    if (weekInt >= currentWeek) {
      return false;
    }
    return true;
  };

  const workoutGroup = workoutGroups.find(
    (workoutGroup) => workoutGroup.week === weekInt
  );
  return (
    <StyledWorkoutPage>
      <Modal open={!!video} onClose={() => setVideo('')}>
        {!!video && (
          <Video
            width={videoWidth}
            height={(videoWidth / 16) * 9}
            video={video}
          />
        )}
      </Modal>
      <h1>
        {showPrevious() && (
          <Link to={`/week/${weekInt - 1}`}>{'<'}</Link>
        )}{' '}
        Semana {week}{' '}
        {showNext() && (
          <Link to={`/week/${weekInt + 1}`}>{'>'}</Link>
        )}
      </h1>
      <StyledWorkoutZone>
        {!!workoutGroup &&
          workoutGroup.workouts.map((workout: IWorkout, index: number) => (
            <Workout key={index} workout={workout} day={index + 1} week={weekInt} setVideoUrl={setVideo} />
          ))}
      </StyledWorkoutZone>
    </StyledWorkoutPage>
  );
}

export default WorkoutPage;

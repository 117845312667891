import React, { FormEvent, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAppContext } from '@web/lib/contextLib';
import SpartansLogo from '@web/assets/spartans_logo.svg';
import moment from 'moment';
import { environment } from "@web/environments/environment";
import { trackEvent } from '@web/lib/plausibleLib'; 

/* eslint-disable-next-line */
export interface LoginProps {}

const StyledLogin = styled.div`
  text-align: center;

  form {
    margin-top: 5rem;
    display: inline-block;
    width: 400px;

    @media all and (max-width: 440px) {
      width: 90%;
    }
  }

  img {
    margin-top: 5rem;
    width: 500px;

    @media all and (max-width: 550px) {
      width: 90%;
    }
  }
`;

const StyledTextInput = styled.input`
  width: calc(100% - 25px); // 100% - padding-left
  padding-left: 25px;
  height: 50px;
  background-color: rgba(238, 238, 238, 0.3);
  border: none;
  border-radius: 25px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: rgb(255, 255, 255);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
`;

const StyledButton = styled.button`
  width: 100%;
  border: none;
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  height: 50px;
  background-color: rgb(9, 169, 17);
  border-radius: 25px;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const StyledError = styled.h3`
  font-family: Montserrat, sans-serif;
  color: red;
`;

export function Login(props: LoginProps) {
  const { userHasAuthenticated } = useAppContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const history = useHistory();

  const currentWeek = moment().isoWeek() - 1;

  function validateForm() {
    return email.length > 4 && password.length > 7;
  }

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    trackEvent('login', { props: { status: 'Submit' }});

    const response = await fetch(`${environment.apiUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email.trim(), password }),
    });

    try {
      const jsonResponse = await response.json();
      if (response.ok) {
        trackEvent('login', { props: { status: 'OK' }});
        userHasAuthenticated(jsonResponse.accessToken);
        history.push(`/week/${currentWeek}`);
        return;
      }
      trackEvent('login', { props: { status: 'Error', message: jsonResponse.message }});
      setError(jsonResponse.message);
      return;
    } catch (error) {
      trackEvent('login', { props: { status: 'Error', message: response.statusText }});
      setError(response.statusText);
      return;
    }
  }

  return (
    <StyledLogin>
      <img width="500px" src={SpartansLogo} alt="Spartans Logo" />
      <br />
      <form onSubmit={handleSubmit}>
        <StyledTextInput
          id="email"
          autoFocus
          type="text"
          value={email}
          placeholder="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        <StyledTextInput
          id="password"
          type="password"
          value={password}
          placeholder="contraseña"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <StyledButton type="submit" disabled={!validateForm()}>
          Iniciar Sesión
        </StyledButton>
      </form>
      <br />
      <Link to="/register">Crear cuenta</Link>
      <StyledError>{error}</StyledError>
    </StyledLogin>
  );
}

export default Login;

import React, { ReactNode } from 'react';

import styled, { StyledFunction } from 'styled-components';

/* eslint-disable-next-line */
export interface ModalProps {
  open: boolean,
  onClose: Function,
  children: ReactNode,
}

interface StyledModalProps {
  show: boolean
}
  
const StyledModal = styled.div<StyledModalProps>`
  display: ${props => props.show ? "block" : "none"};
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

const StyledModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 0px;
  border: 1px solid #888;
  width: fit-content;
  height: fit-content;
  position: relative;
`;

const StyledCloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  width: 32px;
  height: 32px;
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0px;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

export function Modal(props: ModalProps) {
  return (
    <StyledModal show={props.open} onClick={() => props.onClose()}>
      <StyledModalContent onClick={(e) => e.stopPropagation()}>
        <StyledCloseButton onClick={() => props.onClose()}>&times;</StyledCloseButton>
        {props.children}
      </StyledModalContent>
    </StyledModal>
  );
}

export default Modal;

import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import moment from 'moment';

import { AppContext } from '@web/lib/contextLib';
import Login from './pages/login/login';
import Logout from './pages/logout/logout';
import NotFound from './pages/not-found/not-found';
import WorkoutPage from './pages/workout-page/workout-page';
import Register from './pages/register/register';
import { trackPageview } from '@web/lib/plausibleLib'; 

function useStickyState(defaultValue: string, key: string) {
  const [value, setValue] = React.useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
  });
  React.useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
}

export function App() {
  const [isAuthenticated, userHasAuthenticated] = useStickyState(
    '',
    'accessToken'
  );
  const [coach, setCoach] = useStickyState(
    'helios',
    'coach',
  );

  const currentWeek = moment().isoWeek() + 105 - 1;

  const location = useLocation();

  useEffect(() => {
    trackPageview({
      url: location.pathname,
    });
  }, [location.pathname]);

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, coach, setCoach }}>
      <Switch>
        <Route exact path="/week/:week">
          <WorkoutPage />
        </Route>
        <Route exact path="/">
          <Redirect to={`/week/${currentWeek}`} />
        </Route>
        <Route exact path="/week">
          <Redirect to={`/week/${currentWeek}`} />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </AppContext.Provider>
  );
}

export default App;

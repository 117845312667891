import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { History } from 'history';

import { useAppContext } from '../../lib/contextLib';
import { environment } from '@web/environments/environment';

import ReactPlayer from 'react-player';

export interface VideoProps {
  video: string;
  width?: number;
  height?: number;
}

export function Video(props: VideoProps) {
  const video = props.video;
  const [videoUrl, setVideoUrl] = useState('');
  const { isAuthenticated } = useAppContext();
  const history: History = useHistory();

  useEffect(() => {
    if (!video) {
      setVideoUrl('');
      return;
    }

    fetch(`${environment.apiUrl}/videos/${video}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${isAuthenticated}`,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          history.push('/logout');
          return;
        }
        if (response.ok) {
          return response.json();
        }
        throw response.statusText;
      })
      .then((response) => {
        if (response.url) {
          setVideoUrl(response.url);
        }
      })
      .catch((error) => console.error(error));

    return () => setVideoUrl('');
  }, [video]);

  return (
    <ReactPlayer
      playing={!!videoUrl}
      loop={true}
      width={props.width || 300}
      height={props.height || 200}
      url={videoUrl}
      playsinline={true}
      controls={true}
    />
  );
}

export default Video;

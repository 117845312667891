import { useHistory } from 'react-router-dom';
import { useAppContext } from '@web/lib/contextLib';
import { trackEvent } from '@web/lib/plausibleLib'; 

/* eslint-disable-next-line */
export interface LogoutProps {}

export function Logout(props: LogoutProps): null {
  const { userHasAuthenticated } = useAppContext();
  userHasAuthenticated('');
  useHistory().push('/login');
  trackEvent('logout');
  return null
}

export default Logout;

import React, { MouseEvent, ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import styled from 'styled-components';
import { useAppContext } from '../../lib/contextLib';
import { IExercise, IWorkout } from '@spartans/types';
import { trackEvent } from '@web/lib/plausibleLib'; 
import { render } from 'react-dom';

/* tslint-disable-next-line */
export interface WorkoutProps {
  workout: IWorkout;
  setVideoUrl: Function;
  day: number;
  week: number;
}

const StyledWorkout = styled.div`
  padding: 2rem;
  padding-top: 0;
  max-width: 100%;
  scroll-snap-align: start;
  li {
    margin-bottom: 0.5rem;
  }
`;

export function Workout(props: WorkoutProps) {
  const setVideoUrl = props.setVideoUrl;
  const currentWorkout = props.workout;

  const renderStartVideo = (video: string) => {
    const openVideo = (event: MouseEvent) => {
      event.preventDefault();
      trackEvent('video', { props: { type: 'workout', week:props.week, day: props.day}})
      setVideoUrl(video);
    };

    return (
      <h3>
        <b>{'->'} </b>
        <a
          onClick={openVideo}
        >
          Explicación completa
        </a>
        <b> {'<-'}</b>
      </h3>
    );
  };

  const renderWorkout = (
    workoutExercises: IExercise[],
    parentExerciseName?: string
  ): ReactElement[] => {
    const renderExercise = (exercise: IExercise) => {
      const content = (
        <>
          {exercise.optional && '('}
          {exercise.repetitions}
          {exercise.unit === 'seconds' && '"'} {exercise.title || exercise.data?.description}
          {exercise.optional && ')*'}
        </>
      );
      const videoObject = exercise.data?.videos;
      const uuid = uuidv4();
      if (videoObject) {
        let url = '';
        const coaches = Object.keys(videoObject);
        const coach = coaches[Math.floor(Math.random() * coaches.length)];
        switch (coach) {
          case 'eva':
            url = videoObject.eva;
            break;
          case 'dana':
            url = videoObject.dana;
            break;
          case 'helios':
            url = videoObject.helios;
        }

        const openVideo = (event: MouseEvent) => {
          event.preventDefault();
          trackEvent('video', { props: { type: 'exercise', week:props.week, day: props.day, exercise: exercise.name}})
          setVideoUrl(url);
        };

        const videoLink = (
          <a
            onClick={openVideo}
            href="#"
          >
            {content}
          </a>
        );
        return videoLink;
      }
      return content;
    };

    let rows = [];
    for (const exercise of workoutExercises) {
      if (exercise.title && ['repeat', 'emom', 'amrap'].includes(exercise.name)){
        rows.push(<h3 key={uuidv4()}>{exercise.title}</h3>);
      }
      if (exercise.content) {
        if (exercise.name === 'superserie') {
          const superserieExercises = exercise.content.map((subExercise, i) => {
            if (exercise.content.length === i + 1) {
              return renderExercise(subExercise);
            }
            return <>{renderExercise(subExercise)} + </>;
          });

          if (exercise.repetitions) {
            rows.push(<li key={uuidv4()}>{exercise.repetitions} ({superserieExercises} )</li>)
          } else {
            rows.push(<li key={uuidv4()}>{superserieExercises}</li>)
          }
        } else {
          rows.push(
            <ul key={uuidv4()}>
              {renderWorkout(exercise.content, exercise.name)}
            </ul>
          );
          rows.push(
            <h3 key={uuidv4()}>
              {(exercise.name === 'repeat') &&
                exercise.repetitions &&
                'x'}
              {exercise.repetitions}
            </h3>
          );
        }
      } else {
        rows.push(<li key={uuidv4()}>{renderExercise(exercise)}</li>);
      }
    }
    return rows;
  };

  return (
    <StyledWorkout>
      <h1>Dia {props.day}</h1>
      {currentWorkout && renderWorkout(currentWorkout.exercises)}
      {currentWorkout &&
        currentWorkout.video &&
        renderStartVideo(currentWorkout.video)}
      {currentWorkout.notes && currentWorkout.notes}
    </StyledWorkout>
  );
}

export default Workout;
